@font-face {
    font-family: 'Light Of The Mermaid';
    src: url('./light_of_the_mermaid-webfont.woff2') format('woff2'),
         url('./light_of_the_mermaid-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Alternate Gothic No1 D Regular';
  src: url('./alternate-gothic-no1-d-webfont.woff2') format('woff2'),
       url('./alternate-gothic-no1-d-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.alternate-font {
  font-family: "Alternate Gothic No1 D Regular", sans-serif !important;
  line-height: 1 !important;
}

.title-font {
  font-family: "Light Of The Mermaid", serif;
  text-transform: uppercase;
}
