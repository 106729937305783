$global-border: rgba(0,0,0,0.2);
$animation-kenburns-duration: 30s;
$global-font-size: 18px;
$inverse-global-muted-color: rgba(#FFF, 0.75) !default;
$inverse-global-muted-background: rgba(#FFF, 0.2);

@import "uikit/src/scss/variables-theme.scss";

$card-title-font-size: $global-xlarge-font-size;
$base-heading-font-family: "Alternate Gothic No1 D Regular", $global-font-family;
$button-font-size: 22px;

@import "uikit/src/scss/mixins-theme.scss";

@mixin hook-button (){
  font-family: $base-heading-font-family;
  text-transform: uppercase;
}


@import "uikit/src/scss/uikit-theme.scss";

@import "../fonts/stylesheet.css";

.uk-drop-shadow-heavy {
  box-shadow: 0 6px 15px rgba(0,0,0,0.6);
}

.uk-text-shadow {
  text-shadow: 0 2px 4px rgba(0,0,0,0.3), 0 5px 20px rgba(0,0,0, 0.4);
}

.uk-svg-shadow {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3)) drop-shadow(0 5px 20px rgba(0, 0, 0, 0.6));
}

.when-logged-in { display: none; }
.logged-in .when-logged-out { display: none; }
.logged-in .when-logged-in { display: initial; }

.while-loading { display: none; }
.loading .while-loading { display: initial; }
.loading .when-loaded { display: none; }

/* Remove annoying controls on number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] { -moz-appearance: textfield; }

.light-glass {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px) saturate(80%) brightness(95%);
  color: #333;
}

.dark-glass {
  background-color: rgba(0,0,0,0.65);
  -webkit-backdrop-filter: blur(5px) saturate(80%) brightness(95%);
}

.darken::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3)
}

.uk-transition-toggle .desaturate {
  filter: grayscale(100%);
}

.uk-transition-toggle:hover .desaturate {
  filter: grayscale(0%);
}

video {
  outline: none !important
}

.uk-input.StripeElement--focus {
  @extend :focus;
}

.StripeElement--invalid {
  @extend .uk-form-danger;
}

.playbackIssues{
  margin-right:10px;
}
